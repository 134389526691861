<template>
  <section>
    <div class="m-1 mr-4 mb-10 px-0 esteiras">
      <breadcrumb title="Esteiras" actualPage="Pipes" />
    </div>
    <div class="vx-row">
      <div
        class="vx-col w-full md:w-1/3 sm:w-1/2 mb-base"
        v-for="pipe in pipes"
        :key="pipe.id"
        @click="gotoPipe(pipe)"
      >
        <vx-card class="text-center cursor-pointer">
          <feather-icon
            :icon="pipe.iconName || 'BoxIcon'"
            class="p-3 inline-flex rounded-full"
            :class="[`text-primary`, { 'mb-4': !false }]"
            :style="{ background: `rgba(var(--vs-primary),.15)` }"
          ></feather-icon>
          <h4 class="mb-2">{{ pipe.name }}</h4>
          <p class="fix">{{ pipe.description }}</p>
        </vx-card>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Breadcrumb from "@/layouts/components/breadcrumb/Breadcrumb.vue";
export default {
  components: {
    Breadcrumb
  },
  computed: {
    ...mapGetters("pipe-module", ["pipes"])
  },
  methods: {
    ...mapActions("pipe-module", ["getPipes", "getPipe"]),

    async gotoPipe(pipe) {
      await this.$onpoint.loading(async() => {
        return await this.getPipe(pipe.id).then(() =>
          this.$router.push({ name: "kanban-pipe", params: { token: pipe.id } })
        );
      });
    }
  },
  created() {
    this.$appInsights.trackPageView({
      name: "kanban",
      url: window.location.href
    });
  },
  async mounted() {
    await this.$onpoint.loading(async () => {
      return await this.getPipes().catch(err => {
        this.$vs.notify({
          title: "Erro",
          text: "Não foi possível autenticar no serviço de Kanban",
          color: "danger"
        });
      });
    });

    // this.$vs.loading()
    // kanbanProvider.getPipes().then((data) => {
    //   this.$vs.loading.close()
    //   this.pipes = data
    // }).catch((ex) => {
    //   this.$vs.notify({
    //     title:'Erro',
    //     text:'Não foi possível autenticar no serviço de Kanban.',
    //     color:'danger'
    //   })
    //   this.$vs.loading.close()
    // })
  }
};
</script>
<style>
.fix {
  min-height: 40px;
}
@media (max-width: 1600px) {
  .fix {
    min-height: 65px;
  }
}
@media (max-width: 1024px) {
  .fix {
    min-height: auto;
  }
}
@media (max-width: 1100px) {
  .fix {
    min-height: 85px;
  }
}
</style>
