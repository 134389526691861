var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _c(
      "div",
      { staticClass: "m-1 mr-4 mb-10 px-0 esteiras" },
      [_c("breadcrumb", { attrs: { title: "Esteiras", actualPage: "Pipes" } })],
      1
    ),
    _c(
      "div",
      { staticClass: "vx-row" },
      _vm._l(_vm.pipes, function (pipe) {
        return _c(
          "div",
          {
            key: pipe.id,
            staticClass: "vx-col w-full md:w-1/3 sm:w-1/2 mb-base",
            on: {
              click: function ($event) {
                return _vm.gotoPipe(pipe)
              },
            },
          },
          [
            _c(
              "vx-card",
              { staticClass: "text-center cursor-pointer" },
              [
                _c("feather-icon", {
                  staticClass: "p-3 inline-flex rounded-full",
                  class: [`text-primary`, { "mb-4": !false }],
                  style: { background: `rgba(var(--vs-primary),.15)` },
                  attrs: { icon: pipe.iconName || "BoxIcon" },
                }),
                _c("h4", { staticClass: "mb-2" }, [_vm._v(_vm._s(pipe.name))]),
                _c("p", { staticClass: "fix" }, [
                  _vm._v(_vm._s(pipe.description)),
                ]),
              ],
              1
            ),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }